import React from 'react';

import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';

import banner from '../../images/events/2025/atxsg/atxsg-broadcast-asia-2025-event-banner-desktop.jpg';
import mobileBanner from '../../images/events/2025/atxsg/atxsg-broadcast-asia-2025-event-banner-mobile.jpg';
import ogimage from '../../images/events/2025/atxsg/atxsg-broadcast-asia-2025-event-og.jpg';

const BroadcastAsia2025 = () => {
    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='Broadcast Asia - Asia Tech x Singapore 2025 - Witbe' isEvents>
            <SEO
                title='Broadcast Asia - Asia Tech x Singapore 2025 - Witbe'
                ogDescription='Meet us at ATxSG Broadcast Asia from May 27-29! Visit Booth 5H3-1 to discover Witbe’s Virtual NOC technology and book your live demo today.'
                description='Meet us at ATxSG Broadcast Asia from May 27-29! Visit Booth 5H3-1 to discover Witbe’s Virtual NOC technology and book your live demo today.'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={22} banner={mobileBanner} /> : <DesktopEventPage id={22} banner={banner} />}
        </Layout>
    );
};

export default BroadcastAsia2025;
